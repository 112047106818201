<template>
  <div class="favq">
    <headtopsystemgl></headtopsystemgl>

    <div class="enterprise clearfix">
      <div class="left">
        <systemglleft />
      </div>
      <div class="right">
        <div class="soso_box">
          <h1 class="zhaosh_tit">产业导航目录</h1>
        </div>

        <div class="project_main">
          <dl class="project_tit">
            <dt>产业导航目录</dt>
            <dd>
              <span class="xiaz_btn" @click="showpup2 = true">+新增</span>
              <!--弹窗-->
              <div class="pup2" v-if="showpup2"></div>
              <div
                class="dialog2"
                :class="[showscale == false ? 'scale-out' : 'scale-in']"
                id="tools"
                v-if="showpup2"
              >
                <div class="tc_tit">新建</div>
                <ul class="xinzeng_xiajitc">
                  <li>
                    <span>名称：</span>
                    <el-input v-model="admin" placeholder="请输入"></el-input>
                  </li>
                  <li>
                    <span>显示级别：</span>
                    <el-input v-model="admin" placeholder="请输入"></el-input>
                  </li>
                  <li>
                    <span>上级产业：</span>
                    <el-select
                      placeholder="请选择"
                      v-model="industry"
                      @change="labelchange($event, industryarr, 1)"
                    >
                      <el-option
                        v-for="(item, index) in industryarr"
                        :key="index"
                        :label="item.name"
                        :value="item.industry_id"
                      ></el-option>
                    </el-select>
                  </li>
                </ul>
                <div class="btn">
                  <el-button id="but2" @click="showpup2 = false"
                    >取消</el-button
                  >
                  <el-button id="but1" @click="showpup2 = true">确定</el-button>
                </div>
              </div>
              <!--弹窗 end-->
            </dd>
          </dl>
          <div class="project_tables">
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tr>
                <td style="width: 20%">类别</td>
                <td style="width: 20%">字典名称</td>
                <td>操作</td>
              </tr>
              <tr v-for="i in list1" :key="i.id">
                <td>{{ i.cate }}</td>
                <td>{{ i.name }}</td>
                <td>
                  <el-switch
                    v-model="i.statu"
                    active-color="#fe6a00"
                    @change="fieldStatu($event, i.id)"
                    :active-value="1"
                    :inactive-value="2"
                  ></el-switch>
                  <span
                    >{{ i.statu == 1 ? "启用" : "禁用"
                    }}</span
                  >
                  <el-popover placement="bottom" width="320" trigger="click">
                    <ul class="shuju_bianj">
                      <li>
                        <span>导航名称：</span>
                        <el-input v-model="mulu1"></el-input>
                      </li>
                    </ul>
                    <div class="btn">
                      <!--                      <el-button id="but2">取消</el-button>-->
                      <el-button @click="modifyFields(i.id)">确定</el-button>
                    </div>

                    <el-button class="lvzi_btn" slot="reference"
                      >编辑</el-button
                    >
                  </el-popover>
                </td>
              </tr>
            </table>
          </div>

          <dl class="project_tit">
            <dt>产业现状图谱</dt>
          </dl>
          <div class="project_tables">
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tr>
                <td style="width: 20%">序号</td>
                <td style="width: 20%">字典类别</td>
                <td style="width: 20%">字典名称</td>
                <td>操作</td>
              </tr>
              <tr v-for="(i, index) in list2" :key="index">
                <td>{{ index }}</td>
                <td>{{ i.cate }}</td>
                <td>{{ i.name }}</td>
                <td>
                  <el-switch
                    v-model="i.statu"
                    active-color="#fe6a00"
                    @change="propertytyStatu($event, i.id)"
                    :active-value="1"
                    :inactive-value="2"
                  ></el-switch>
                  <span>{{ i.statu == 1 ? "启用" : "禁用" }}</span>
                  <el-popover placement="bottom" width="320" trigger="click">
                    <ul class="shuju_bianj">
                      <li>
                        <span>导航名称：</span>
                        <el-input v-model="mulu1"></el-input>
                      </li>
                    </ul>
                    <div class="btn">
                      <!--                      <el-button id="but2">取消</el-button>-->
                      <el-button @click="modifyPropertyty(i.id)"
                        >确定</el-button
                      >
                    </div>

                    <el-button class="lvzi_btn" slot="reference"
                      >编辑</el-button
                    >
                  </el-popover>
                </td>
              </tr>
            </table>
          </div>

          <dl class="project_tit">
            <dt>产业招商地图&360企业画像</dt>
          </dl>
          <div class="project_tables">
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tr>
                <td style="width: 20%">序号</td>
                <td style="width: 20%">字典类别</td>
                <td style="width: 20%">字典名称</td>
                <td>操作</td>
              </tr>
              <tr v-for="(i, index) in list3" :key="index"> 
                <td>{{ index }}</td>
                <td>{{ i.cate }}</td>
                <td>{{ i.name }}</td>
                <td>
                  <el-switch
                    v-model="i.statu"
                    active-color="#fe6a00"
                    @change="propertyStatu($event, i.id)"
                    :active-value="1"
                    :inactive-value="2"
                  ></el-switch>
                  <span>{{ i.statu == 1 ? "启用" : "禁用" }}</span>
                  <el-popover placement="bottom" width="320" trigger="click">
                    <ul class="shuju_bianj">
                      <li>
                        <span>导航名称：</span>
                        <el-input v-model="mulu1"></el-input>
                      </li>
                    </ul>
                    <div class="btn">
                      <!--                      <el-button id="but2">取消</el-button>-->
                      <el-button @click="modifyProperty(i.id)">确定</el-button>
                    </div>

                    <el-button class="lvzi_btn" slot="reference"
                      >编辑</el-button
                    >
                  </el-popover>
                </td>
              </tr>
            </table>
          </div>

          <dl class="project_tit">
            <dt>招商资源管理</dt>
            <dd>
              <span class="xiaz_btn" @click="showpup = true">+新增</span>
              <!--弹窗-->
              <div class="pup" v-if="showpup"></div>
              <div
                class="dialog"
                :class="[showscale == false ? 'scale-out' : 'scale-in']"
                id="tools"
                v-if="showpup"
              >
                <div class="tc_tit">新建</div>
                <ul class="xinzeng_xiajitc">
                  <li>
                    <span>字典名称：</span>
                    <el-input v-model="admin" placeholder="请输入"></el-input>
                  </li>
                  <li>
                    <span>字典类别：</span>
                    <el-input v-model="admin" placeholder="请输入"></el-input>
                  </li>
                </ul>
                <div class="btn">
                  <el-button id="but2" @click="showpup = false">取消</el-button>
                  <el-button id="but1" @click="showpup = true">确定</el-button>
                </div>
              </div>
              <!--弹窗 end-->
            </dd>
          </dl>
          <div class="project_tables">
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tr>
                <td style="width: 20%">序号</td>
                <td style="width: 20%">字典类别</td>
                <td style="width: 20%">字典名称</td>
                <td>操作</td>
              </tr>
              <tr v-for="(i, index) in list4" :key="index">
                <td>{{ index }}</td>
                <td>{{ i.cate }}</td>
                <td>{{ i.name }}</td>
                <td>
                  <el-switch
                    v-model="i.statu"
                    active-color="#fe6a00"
                    @change="attractStatu($event, i.id)"
                    :active-value="1"
                    :inactive-value="2"
                  ></el-switch>
                  <span>{{ i.statu == 1 ? "启用" : "禁用" }}</span>
                  <el-popover placement="bottom" width="320" trigger="click">
                    <ul class="shuju_bianj">
                      <li>
                        <span>导航名称：</span>
                        <el-input v-model="mulu1"></el-input>
                      </li>
                    </ul>
                    <div class="btn">
                      <!--                      <el-button id="but2">取消</el-button>-->
                      <el-button @click="modifyAttract(i.id)">确定</el-button>
                    </div>

                    <el-button class="lvzi_btn" slot="reference"
                      >编辑</el-button
                    >
                  </el-popover>
                </td>
              </tr>
            </table>
          </div>

          <dl class="project_tit">
            <dt>园区信息管理</dt>
            <dd>
              <span class="xiaz_btn" @click="showpup3 = true">+新增</span>
              <!--弹窗-->
              <div class="pup" v-if="showpup3"></div>
              <div
                class="dialog"
                :class="[showscale == false ? 'scale-out' : 'scale-in']"
                id="tools"
                v-if="showpup3"
              >
                <div class="tc_tit">新建</div>
                <ul class="xinzeng_xiajitc">
                  <li>
                    <span>字典名称：</span>
                    <el-input v-model="admin" placeholder="请输入"></el-input>
                  </li>
                  <li>
                    <span>字典类别：</span>
                    <el-input v-model="admin" placeholder="请输入"></el-input>
                  </li>
                </ul>
                <div class="btn">
                  <el-button id="but2" @click="showpup3 = false"
                    >取消</el-button
                  >
                  <el-button id="but1" @click="showpup3 = true">确定</el-button>
                </div>
              </div>
              <!--弹窗 end-->
            </dd>
          </dl>
          <div class="project_tables">
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tr>
                <td style="width: 20%">序号</td>
                <td style="width: 20%">字典类别</td>
                <td style="width: 20%">字典名称</td>
                <td>操作</td>
              </tr>
              <tr v-for="(i, index) in list5" :key="index">
                <td>{{ index }}</td>
                <td>{{ i.cate }}</td>
                <td>{{ i.name }}</td>
                <td>
                  <el-switch
                    v-model="i.statu"
                    active-color="#fe6a00"
                    @change="zoneStatu($event, i.id)"
                    :active-value="1"
                    :inactive-value="2"
                  ></el-switch>
                  <span>{{ i.statu == 1 ? "启用" : "禁用" }}</span>
                  <el-popover placement="bottom" width="320" trigger="click">
                    <ul class="shuju_bianj">
                      <li>
                        <span>导航名称：</span>
                        <el-input v-model="mulu1"></el-input>
                      </li>
                    </ul>
                    <div class="btn">
                      <!--                      <el-button id="but2">取消</el-button>-->
                      <el-button @click="modifyZone(i.id)">确定</el-button>
                    </div>

                    <el-button class="lvzi_btn" slot="reference"
                      >编辑</el-button
                    >
                  </el-popover>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>




<script>
import {
  listField,
  fieldStatu,
  modifyFields,
  listPropertyty,
  modifyPropertyty,
  propertytyStatu,
  modifyProperty,
  propertyStatu,
  listProperty,
  listAttract,
  attractStatu,
  modifyAttract,
  listZone,
  zoneStatu,
  modifyZone,
} from "@/api/index";
export default {
  data() {
    return {
      value1: true,
      value2: true,
      showscale: true,
      showpup: false,
      showscale2: true,
      showpup2: false,
      showscale3: true,
      showpup3: false,
      list1: [],
      list2: [],
      list3: [],
      list4: [],
      list5: [],
      mulu1: "",
      switchbol: true,
    };
  },
  created() {
    this.listField();
    this.listPropertyty();
    this.listProperty();
    this.listAttract();
    this.listZone();
  },
  methods: {
    // 列表1
    async listField() {
      const res = await listField({});
      this.list1 = res;
    },
    async fieldStatu(e, a) {
      if (e == 1) {
        this.statu = 2;
      } else {
        this.statu = 1;
      }
      const res = await fieldStatu({
        id: a,
        statu: this.statu,
      });
      if (res.code == 0) {
        // this.listField();
      }
    },
    async modifyFields(id) {
      const res = await modifyFields({
        id: id,
        newname: this.mulu1,
      });
      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.getorgStaff();
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
      this.listField();
    },
    // 列表2
    async listPropertyty() {
      const res = await listPropertyty({});
      this.list2 = res;
    },
    async propertytyStatu(e, a) {
      if (e == true) {
        this.statu = 2;
      } else {
        this.statu = 1;
      }
      const res = await propertytyStatu({
        id: a,
        statu: this.statu,
      });
    },
    async modifyPropertyty(id) {
      const res = await modifyPropertyty({
        id: id,
        newname: this.mulu1,
      });
      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.getorgStaff();
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
      this.listPropertyty();
    },
    //列表3
    async listProperty() {
      const res = await listProperty({});
      this.list3 = res;
    },
    async propertyStatu(e, a) {
      if (e == true) {
        this.statu = 2;
      } else {
        this.statu = 1;
      }
      const res = await propertyStatu({
        id: a,
        statu: this.statu,
      });
    },
    async modifyProperty(id) {
      const res = await modifyProperty({
        id: id,
        newname: this.mulu1,
      });
      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.getorgStaff();
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
      this.listProperty();
    },
    //列表4
    async listAttract() {
      const res = await listAttract({});
      this.list4 = res;
    },
    async attractStatu(e, a) {
      if (e == true) {
        this.statu = 2;
      } else {
        this.statu = 1;
      }
      const res = await attractStatu({
        id: a,
        statu: this.statu,
      });
    },
    async modifyAttract(id) {
      const res = await modifyAttract({
        id: id,
        newname: this.mulu1,
      });
      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.getorgStaff();
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
      this.listAttract();
    },

    //列表5
    async listZone() {
      const res = await listZone({});
      this.list5 = res;
    },
    async zoneStatu(e, a) {
      if (e == true) {
        this.statu = 2;
      } else {
        this.statu = 1;
      }
      const res = await zoneStatu({
        id: a,
        statu: this.statu,
      });
    },
    async modifyZone(id) {
      const res = await modifyZone({
        id: id,
        newname: this.mulu1,
      });
      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: "success",
        });
        this.getorgStaff();
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
      this.listZone();
    },
  },
};
</script>





<style lang="less" scoped>
.favq {
  width: 100%;
  min-height: 100%;
  background: rgb(243, 243, 243);
  padding-bottom: 30px;
  // .enterprise {
  //   min-height: 100%;
  //   background: rgb(243, 243, 243);
  // }
  li {
    list-style: none;
  }

  .c-top {
    width: 1920px;
    height: 72px;
    background: #fff;

    li {
      display: block;
      width: 100px;
      height: 38px;
      background: #eee;
      margin-top: 19px;
      border-radius: 6px;
      margin-right: 19px;
      font-size: 17px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #666666;
      text-align: center;
      line-height: 38px;
      float: left;
      &:nth-child(1) {
        margin-left: 52px;
      }
      &.active {
        background: rgb(254, 147, 34);
        color: #ffffff;
      }
    }
  }
}
.enterprise {
  padding: 0 30px;
}
.left {
  float: left;
  background: #fff;
  min-width: 170px;

  .dxhj {
    padding-top: 13px;
    width: 100%;
    height: 38px;

    img {
      float: left;
      width: 38px;
      height: 38px;
      margin-left: 13px;
    }

    span {
      float: left;
      line-height: 31px;
      height: 18px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #222222;
    }
  }

  /deep/ .el-collapse-item__header {
    height: 40px;
    padding-left: 40px;
    font-size: 14px;
  }

  /deep/ .el-collapse-item__header.is-active {
    color: #fe6a00;
  }
}
.seach {
  display: inline-block;
  height: 30px;
  background: #fe6a00;
  border-radius: 4px;
  line-height: 30px;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  color: #ffffff;
  margin: 1% 0;
  padding: 0 30px;
}
.right {
  width: calc(100% - 256px - 15px);
  // overflow-y: auto;
  float: right;
  margin-left: 10px;

  .project_main {
    background: #fff;
    padding: 18px 22px;
    overflow: hidden;
    zoom: 1;

    .project_tables {
      font-size: 14px;
      line-height: 44px;
      margin-bottom: 20px;
      border-bottom: 1px solid #eee;

      tr:nth-child(odd) {
        background: #f3f6fb;

        td {
          border-top: 1px solid #eee;
          border-bottom: 1px solid #eee;
        }
      }
      span {
        margin: 0 2%;
      }
      .chengzi_btn {
        color: #fe6a00;
      }
      .lvzi_btn {
        color: #2cb54c;
      }
      .zizi_btn {
        color: #135de6;
      }
      .redzi_btn {
        color: #e51313;
      }
      .bluezi_btn {
        color: #0ebbf3;
      }
      .huangzi_btn {
        color: #e69554;
      }

      /deep/.el-button {
        background: none;
        padding: 0;
        border: 0;
      }
    }

    //分页
    /deep/.el-pagination {
      float: right;
      margin-top: 20px;
    }
    /deep/.btn-prev {
      border: 1px solid #888;
      padding: 0;
    }
    /deep/.btn-next {
      border: 1px solid #888;
      padding: 0;
    }
    /deep/.el-pager li {
      border: 1px solid #888;
      margin: 0 3px;
      min-width: 27px;
    }
    /deep/.el-pager li.active,
    /deep/.el-pager li:hover {
      border: 1px solid #fe6a00;
      color: #fe6a00;
    }

    //分页end
  }
}

.soso_box {
  background: #fff;
  padding: 22px;
  margin-bottom: 15px;
  overflow: hidden;
  zoom: 1;
}
.zhaosh_tit {
  font-size: 18px;
  font-weight: bold;
  text-align: left;
}

.project_tit {
  overflow: hidden;
  zoom: 1;
  margin: 0px 0 6px 0;
}
.project_tit dt {
  float: left;
  font-weight: bold;
  font-size: 16px;
  color: #fe6a00;
  height: 30px;
  line-height: 30px;
}

.project_tit dd {
  float: right;
}
.xiaz_btn {
  display: inline-block;
  height: 30px;
  line-height: 30px;
  background: #fe6a00;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
  color: #fff;
  margin: 1% 5px;
  padding: 0 20px;
  cursor: pointer;
}
/deep/.el-switch.is-checked .el-switch__core {
  border-color: #fe6a00;
  background-color: #fe6a00;
}
/deep/.el-switch__label.is-active {
  color: #666;
}
/deep/.el-switch {
  margin-right: 2%;
}

//弹窗
.pup,
.pup2 {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
.scale-in {
  animation: scaleIn 0.25s both;
}
.scale-out {
  animation: scaleOut 0.25s oth;
  display: none;
}

.dialog,
.dialog2 {
  background-color: #fff;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.15);
  width: 347px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 9999;
  // display: none;

  .close {
    float: right;
  }
}
.btn {
  text-align: center;
  margin: 30px 0;
  #but1 {
    width: 72px;
    height: 30px;
    background: #fe6a00;
    border: 1px solid #fe6a00;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 30px;
    padding: 0;
  }
  #but2 {
    width: 72px;
    height: 30px;
    background: #ffffff;
    border: 1px solid #fe6a00;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 400;
    color: #fe6a00;
    line-height: 30px;
    padding: 0;
  }
}
/deep/.el-input {
  width: 100%;
  height: 30px;
  input {
    border-radius: 0;
    width: 100%;
    height: 30px;
    color: #333;
    background: #ffffff;
    border: 1px solid #dddddd;
  }
  ::-webkit-input-placeholder {
    color: #333;
  }
  /deep/.el-icon-circle-close {
    background: url("../../assets/merchantsradar/cuo.png") 50% 50% no-repeat;
  }
  .el-icon-circle-close:before {
    content: "";
  }
}
/deep/.el-select {
  width: 70%;
  height: 30px;
  input {
    width: 100%;
    height: 30px;
    background: #ffffff;
    border: 1px solid #dddddd;
  }
  /deep/.el-input__icon {
    line-height: 30px !important;
  }
}
/deep/.el-textarea__inner:focus {
  border-color: #fe6a00;
}
/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #fe6a00;
  border-color: #fe6a00;
}

.tc_tit {
  height: 54px;
  line-height: 54px;
  color: #fe6a00;
  padding-left: 20px;
  font-size: 16px;
  text-align: left;
  background: #fff3eb;
}
.xinzeng_xiajitc {
  margin: 20px;
  overflow: hidden;
  zoom: 1;
}
.xinzeng_xiajitc li {
  list-style: none;
  margin-bottom: 10px;
  overflow: hidden;
  zoom: 1;
}
.xinzeng_xiajitc li span {
  display: block;
  width: 82px;
  float: left;
  height: 30px;
  line-height: 30px;
}
.xinzeng_xiajitc li .el-input {
  width: 206px;
}
.xinzeng_xiajitc li .el-select {
  width: 206px;
}

.shuju_bianj {
  margin: 20px 0 10px 10px;
  overflow: hidden;
  zoom: 1;
}
.shuju_bianj li {
  list-style: none;
  margin-bottom: 10px;
  overflow: hidden;
  zoom: 1;
}
.shuju_bianj li span {
  display: block;
  width: 82px;
  float: left;
  height: 30px;
  line-height: 30px;
  text-align: right;
}
.shuju_bianj li .el-input {
  width: 186px;
}
.shuju_bianj li .el-select {
  width: 186px;
}
</style>
